/* textures */
$paper: url(../img/back.jpg);

/* black and white */
$charcoal: #2f3036;
$offwhite: #FFF;
$gray: mix($charcoal, $offwhite);

/* colors */

$maincolor: #ff9729;
$selectcolor: adjust-hue($maincolor, 25deg);

$complementcolor: complement($maincolor);
$bordercolor: darken($complementcolor, 20%);

/* fonts */
$stack-ss: 'Raleway', Helvetica, sans-serif;
$stack-serif: 'Vollkorn', Athelas, Georgia, serif;
$stack-logo: 'Cabin Sketch', $stack-ss;
$stack-mono: 'Source Code Pro', Menlo, Consolas, Monaco, monospace;

/* borders */
$standard-border: solid 2px;

/* shadows */
$shadow: 0 1px 0 $gray;