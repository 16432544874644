.widgetbox {
    text-align: center;
    box-sizing: border-box;
    font-size: 1em;
    width: 100%;
    font-family: 'Cabin Sketch';
    /*margin-top: -3em;*/
	.widget {
		@extend .boxshadow;
		float: left;
	    width: 33.3%;
	    background: $offwhite;
	    position: relative;
	    height: 300px;
	    margin-bottom: 3em;
	    p {
	    	padding: 0 1.5em;
		    text-shadow: 0 1px 0 $gray;
		}
	    p.icon {
		    font-size: 4em;
		    margin: 20px 0 0;
		    padding: 0;
		    text-align: center;
		    color: $maincolor;
		    text-shadow: 0 1px 0 $gray;
		}
		h3 {
		    margin: 0;
		    font-size: 2.85em;
		    color: $maincolor;
		}
	}
}