/* Project Pane */

#projectpane > h1 {
  text-align: center;
}

.post-group {
  min-height: 500px;
  text-align: center;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0 auto;
  width: 1250px;
}

.post-group > .post {
	margin-top: 0.5em;
	float: left;
	margin-left: 10px;
}

.post img {
  width: 100% !important;
  height: auto !important;
  /* border-radius: 30px; */
}

.post a {
  display: inline-block;
  max-width: 100%;
  position: relative;  
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  box-sizing: border-box;
}

.post a img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.post img {
	-webkit-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
	-moz-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
	box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
}

.caption {
  position: absolute;
  top:0;
  left: 0; 
  width: 100%;
  height: 100%;
  color:white;
  opacity: 0;
  transition:opacity .5s ease;
  /* border-radius: 30px; */
}

.post a:hover .caption, .post a:active .caption {
	opacity: 1;
	background: rgba($complementcolor, 0.5);
	transition: opacity .5s ease;
	box-shadow: inset 0 0 90px -20px $charcoal;
}

.post a:hover img, .post a:active img {
	-webkit-filter:blur(2px);
	filter: blur(2px); 
}

.caption-text {
    text-shadow: $shadow;
    h3.title {
		font-family: $stack-ss;
		font-size: 2em;
		margin: 4em 1em 0.25em 1em;
		line-height: 1.1;
		font-weight: bold;
    }
    p {
    	font-family: $stack-serif;
    	margin: 0 0 0 2em;
    	font-style: italic;
    }
}
