body {
	margin: 0;
	color: $charcoal;
    // opacity: 0.9;
    // transition: 1s opacity;
 }
// body.fade-out {
//     opacity: 0;
//     transition: none;
// }

// a {
//     text-decoration: none;
// 	color: $maincolor;
// 		&:-webkit-any-link {
// 			cursor: pointer !important;
// 		}
// 		&:hover {
// 	        color: darken($maincolor, 5%);
//             cursor: pointer;
// 	    }
//         &:active {
//         	color: darken($maincolor, 15%);
//         }
// }

::selection {
	background: $selectcolor;
}

::-moz-selection {
	background: $selectcolor;
}
h1, h1#aboutme {
	margin-bottom: 0.5em;
	padding-bottom: 0;
	text-shadow: $shadow;
// 	font-size: 2.5em;
	text-align: left;
	font-family: $stack-ss;
}
h1#aboutme {
	border-bottom: none;
}
p {
	font-family: $stack-serif;
	line-height: 1.3;
	padding-bottom: 1em;
	font-size: 1em;
	widows: 2;
	orphans: 2;
	margin: 0;
}
li, li ul li {
	font-family: $stack-serif;
	font-size: 1em;
	widows: 2;
	orphans: 2;	
}
article {
	margin: 0 auto;
	max-width: 700px;
	margin-top: 1px;
}
 article#aboutarticle {
//     margin: 0 auto;
//     max-width: 900px;
//     padding: 0;
//     -moz-columns: 2 40px;
//     columns: 2 40px;
//     -webkit-column-gap: 35px;
//     -moz-column-gap: 35px;
//     column-gap: 35px;
//    border-bottom: none;
//     margin-top: 2em;
	 padding-top: 1em;
	 p {
	 	font-size: 1.2em;
	 }
	
 }


img {
	max-width: 100%;
}

.center {
	text-align: center;
	font-family: $stack-ss;
	font-weight: bold;
    font-size: 1em;
}
