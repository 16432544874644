@mixin columns($number, $width, $gutter, $span) {
	-webkit-columns: $number $width;
     -moz-columns: $number $width;
          columns: $number $width;
	-webkit-column-gap: $gutter;
     -moz-column-gap: $gutter;
          column-gap: $gutter;
      -webkit-column-span: $span; 
          column-span: $span; 
	-moz-column-fill: auto;
       column-fill: auto;
}

@mixin gradient($deg, $color1, $color2, $color3) {
  background: -moz-linear-gradient($deg, $color1, $color2, $color3);
  background: -webkit-linear-gradient($deg, $color1, $color2, $color3);
  background: linear-gradient($deg, $color1, $color2, $color3);
}    
    

@mixin button($buttoncolor, $corner-tl, $corner-tr, $corner-br, $corner-bl) {
  border-top-left-radius: $corner-tl;
  border-top-right-radius: $corner-tr;
  border-bottom-right-radius: $corner-br;
  border-bottom-left-radius: $corner-bl;
  line-height: 1.3;
  background-color: $buttoncolor;
  display: inline-block;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-color: darken($buttoncolor, 5%);
  border: 1px solid transparent;
  text-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  &:hover {
    background-color: darken($buttoncolor, 5%)
    }
  &:active {
    background-color: darken($buttoncolor, 15%)
    }   
}

.boxshadow {
    -webkit-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
    box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
  }