footer {
	float: left;
    // position: relative;
    color: $charcoal;
    text-align: right;
    width: 100%;
    font-family: $stack-serif;
    margin-top: 2em;
    border-top: 1px solid $gray;
    font-size: 1em;
    padding-top: 1em;
    .copyright {
		padding-left: 1em;
    }
    .social {
    	padding-left: 1em;
    	a {
    		font-style: italic;
    	}
    }
}
