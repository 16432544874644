
@keyframes pulse {
  0% {
    background-color: #fd8282;
  }
  10% {
  	background-color: #fdfc82;
  }
  25%{
	 background-color: #82fd8c;
  }
  50% {
  	background-color: #af9eff;
  }
  75% {
  	background-color: #e782fd;
  }
  85% {
  	background-color: #82defd;  	
  }
  100% {
    background-color: #fd8282;
  }
}

@keyframes textpulse {
    0% {
    color: #fd8282;
  }
  10% {
    color: #fdfc82;
  }
  25%{
   color: #82fd8c;
  }
  50% {
   color: #af9eff;
  }
  75% {
   color: #e782fd;
  }
  85% {
   color: #82defd;    
  }
  100% {
   color: #fd8282;
  }
}

@keyframes pulsebutton {
  10% {
    background-color: #fd8282;
  }
  25% {
    background-color: #fdfc82;
  }
  50%{
   background-color: #82fd8c;
  }
  75% {
    background-color: #af9eff;
  }
  85% {
    background-color: #e782fd;
  }
  100% {
    background-color: #82defd;    
  }
  0% {
    background-color: #fd8282;
  }
  
  0% {
    color: #fd8282;
  }
  10% {
    color: #fdfc82;
  }
  25%{
   color: #82fd8c;
  }
  50% {
   color: #af9eff;
  }
  75% {
   color: #e782fd;
  }
  85% {
   color: #82defd;    
  }
  100% {
   color: #fd8282;
  }
}


