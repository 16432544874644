@import 'normalize';
@import 'vars';
@import 'fonts';
@import 'mixins';
@import 'animations';

@import 'base';
@import 'header';
@import 'footer';
@import 'services';
@import 'nav';
@import 'posts'; 
@import 'codesyntax';
@import 'forms';
@import 'buttons';
@import 'thanks';
@import 'sideNav';
@import 'projectpane';
@import 'error'; 

@import 'font-awesome';

@import 'media-queries'; 
