/* css for post contact page */

main#thanks {
  	margin: 0 auto;
    animation: pulse 10s infinite;
    min-height: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 12em;
    p {
		  text-align: center;
    }
    button {
      animation: pulsebutton 10s infinite;
      display: block;
      margin: 0 auto;
      @extend .boxshadow;
    }
}
