.info {
   	display: inline-grid;
    margin-right: 10%;
    margin-top: 25px;  
    
    ul {
      margin-top: 0.25em;
      list-style-type: none;
      list-style: none;
      padding-left: 0;
      margin-top: 0;
    }

&::before {
    content: attr(title);
    text-transform: uppercase;
    font-size: 0.75em;
    letter-spacing: 0.2em;
    color: $charcoal;
    display: block;
    margin-bottom: 0;
    font-family: $stack-ss;
	text-shadow: $shadow;
 }
}

h1#projects {
	border-bottom: none;
}

p img {
    display: block;
    margin: 0 auto 0.5em;
    padding-bottom: 0;
    -webkit-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
	-moz-box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
	box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.54);
}

div#blog-roll {
	@extend article;
	.post {
		padding: 1em;
		// @extend .boxshadow;
		border-bottom: 1px solid $bordercolor;
		p.meta, h3.title {
			margin: 0;
		}
	}
}

article {
// 	margin: 0 auto;
//     max-width: 50%;
    padding-top: 1px;
    // &#xp {
    // 	margin-top: 8em;
    // }
	section {
		border-bottom: 1px solid $bordercolor;
    }
	header {
		font-family: $stack-ss;
		padding-bottom: 9px;
		/* margin: 40px 0 20px; */
		border-bottom: 1px solid $bordercolor;
		text-align: center;
		line-height: 1.4;
		text-shadow: $shadow;

		h1.post-title {
			/*margin: 0em 0.67em;	*/
			hyphens: none;
// 			font-size: 2.35em;
			text-align: left;
		}
		p.byline {
			font-size: 1em;
			line-height: 1;
			//margin-top: 1em;
			text-align: left;
			//margin-bottom: 1em;
			font-family: $stack-ss;
			color: $complementcolor;
		}
		p.post-info {
			font-size: 1em;
			margin-top: 0;
			line-height: 1;

		}
	}
	section {
		p {
			margin-top: 0.5em;
			font-size: 1em;
			line-height: 1.5;
			widows: 2;
			orphans: 2;
			font-family: $stack-serif;
		}
	}
}

article.blogpost {
	padding-top: 9.5em;
}

// article#xp {
// 	margin: 0 auto;
//     padding: 1px 25%;
//     @extend .boxshadow;

// }

article.project {
// 	margin: 0 auto;
//     max-width: 900px;
	/*max-width: 100%;
	@extend .boxshadow;*/
	header {
		margin: 0 auto;
		h1 {
		margin: 4.5em 0 0;
        text-align: left;
		}
        
        h2 {
          text-align: left;
          text-transform: uppercase;
          font-size: 0.75em;
          font-weight: normal;
          color: $charcoal;
          letter-spacing: 0.2em;
        }
		
		p.byline {
			font-size: 1em;
			line-height: 1;
			margin-top: 1em;
			text-align: center;
			margin-bottom: 3em;
		}
		p.post-info {
			font-size: 1em;
			margin-top: 0;
			line-height: 1;

		}
	}
	section {
		p, ul li {
			margin-top: 0.5em;
			font-size: 1em;
			line-height: 1.5;
			/*margin-left: 10%;
			margin-right: 10%;*/
			widows: 2;
			orphans: 2;
			font-family: $stack-serif;
		}
		ul li {
			line-height: 1;
		}
		h3 {
			font-size: 1.25em;
			/*margin-left: 10%;
			margin-right: 10%;*/
			font-family: $stack-ss;
		}

	}
}


img + em {
    text-align: center; 
    display: block;
    margin: 0;
    margin-bottom: 1em;    


}

aside.bio {
		margin-top: 1em;
		font-size: 1em;
		font-style: italic;
		margin: 0;
		border-top: 1px solid $bordercolor;
		font-family: $stack-serif;
		h2 {
			text-align: left;
			font-size: 1.1em;
			font-style: normal;
			font-family: $stack-ss;
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
		i {
			font-style: normal;
		}	
	}