header.site-header 	{
  	// width: 100%;
	display: block;
   	// padding: 1.5em 0 4em;
	// text-align: center;
	text-indent: 0;
	box-sizing: border-box;
	color: $charcoal;
	margin: 0 auto;
	// background: $paper;
	text-shadow: $shadow;
	font-family: $stack-ss;
	max-width: 700px;
	h1 {
		margin: 0.67em;
		font-size: 4em;
		padding-top: 2em;
		line-height: 1.2;
		margin: 0;
		font-family: $stack-logo;
		font-weight: bold;
		border-bottom: none;
	}
	h2 {
		margin: 0.5em;
		font-family: $stack-logo;
		font-weight: bold;
	}
	/*h3 {
		font-size: 1em;
		line-height: 1.2;
		font-style: italic;
		margin: 0;
		font-family: $stack-ss;
		color: $charcoal;
	}*/
	p.tag-line {
		font-style: italic;
		color: $complementcolor;
		line-height: 1.5;
		font-family: $stack-serif;
		margin: 0;
		padding: 0;
		font-size: 1.3em;
		font-style: italic;
		}
	}
	// div.strip {
	// 	background-color: #fff;
	// 	opacity: 0.55;
	// 	display: block; 
	// 	p.header-links {
	// 		font-size: 2.5em;
	// 		border-bottom: $standard-border;
	// 		padding-bottom: 1em;
	// 		font-family: $stack-ss;
	// 		margin: 0;
	// 	}
mark {
	color: $charcoal;
}