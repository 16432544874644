input#user-input:focus {
    outline: none;
    border-bottom: solid 2px $maincolor;
    font-weight: normal;
}

input#user-input {
    border: none;
    width: 95%;
    font-weight: bold;
    text-align: right;
    padding-right: 5px;
}

p#game-input {
     width: 100%;
    margin: 10em 0 2em 0;   
}
