@media screen and (max-width: 1250px) {
  .post-group {
    width: 950px;
  }
}

@media screen and (max-width: 1075px) {
  div#logo {
    display: none;
  }
  nav.topnav {
    ul#menu {
      position: static;
      display: none;
      margin: 0;
    li#contactme, li#name, li, li a {
        width: 100%;
        background-color: $offwhite;
        margin-left: 0;
        margin-bottom: 1px;
        padding: 0;
      }
    li#name {
      font-size: 1.5em;
      }
    }
    .show-menu {
      display:block;
      }
  }
  article, article#xp {
    /*margin: 0 5%;*/
    max-width: 100%;
    padding: 1px 20px;

  }
  header.site-header > h1 {
    padding-left: 20px;
    padding-top: 2em;
  }
}

@media screen and (max-width: 945px) {
  .post-group {
    text-align: center;
    overflow: auto;
    box-sizing: border-box;
    font-size: 1em;
    width: 625px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 780px) {
  .widgetbox {
    .widget {
      width: 100%;
      margin-bottom: 0;
      height: auto;
    }
  }
  h1#aboutme {
      margin-top: 1em;
      padding-top: 18em;
    }
}

@media screen and (max-width: 650px) {
  .post-group {
    width: 100%;
    .post {
      width: 100%;
    }
  }
}


@media screen and (max-width: 580px) {
    div#form-container{
        width: 88%;
        margin-left: 0;
        padding-left: 3%;
        padding-right: 3%;
        z-index: 200;
        margin-top: 0;
    }
      button.left-btn, button.right-btn {
      font-size: 1em;
    }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}