div#form-container {
    box-shadow: 0px 0px 100px 1000px rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: 0px 0px 100px 1000px rgba(255, 255, 255, 0.7);
    -moz-box-shadow: 0px 0px 100px 1000px rgba(255, 255, 255, 0.7);
    padding-top: 0px;
    background-color: $charcoal;
    padding: 1em;
    font-family: $stack-ss;
    width: 25em;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    margin: 5em auto 0;
    font-size: 1em;
    display: none;
    text-align: center;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);

    input, textarea {
        color:$charcoal;
        font-family: $stack-ss;
        font-weight:500;
        font-size: 18px;
        border-radius: 0;
        line-height: 22px;
        background-color: $offwhite;
        padding: 1em;
        margin-bottom: 0.5em;
        width:100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        border: 3px solid rgba(0,0,0,0);
        display: block;
    }

    input:focus, textarea:focus {
        background: white;
        box-shadow: 0;
        border: 3px solid $selectcolor;
        color: $selectcolor;
        outline: none;
    }

    textarea {
        width: 100%;
        height: 150px;
        line-height: 150%;
        resize:vertical;
    }

    input:hover, textarea:hover,
    input:focus, textarea:focus {
        background-color:white;
    }

    h1 {
        color: $offwhite;
        border-bottom: none;
        margin-top: 0;
        font-weight: normal;
        font-size: 2em;
    }
}