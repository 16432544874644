.highlight {
    @extend .boxshadow;
	background-color: $charcoal;
	padding: 0.5em 2em;
    padding-left: 1em;
    font-family: $stack-mono;
    margin: 0 auto;
    width: 75%;
    color: $offwhite;
    code {
        padding-left: 6px;
    }
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    padding-right: 1.5em;
}

.highlight .c { color: #d2d2d2; font-style: italic } /* Comment */
.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
.highlight .k { font-weight: bold } /* Keyword */
.highlight .o { font-weight: bold } /* Operator */
.highlight .cm { color: #d2d2d2; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #d2d2d2; font-weight: bold } /* Comment.Preproc */
.highlight .c1 { color: #d2d2d2; font-style: italic } /* Comment.Single */
.highlight .cs { color: #d2d2d2; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
.highlight .gd .x { color: #000000; background-color: #ffaaaa } /* Generic.Deleted.Specific */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gr { color: #f63d3d } /* Generic.Error */
.highlight .gh { color: #d2d2d2 } /* Generic.Heading */
.highlight .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
.highlight .gi .x { color: #000000; background-color: #aaffaa } /* Generic.Inserted.Specific */
.highlight .go { color: #fbdada } /* Generic.Output */
.highlight .gp { color: #f6f6f6 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #f2d8d8 } /* Generic.Subheading */
.highlight .gt { color: #f63d3d } /* Generic.Traceback */
.highlight .kc { font-weight: bold } /* Keyword.Constant */
.highlight .kd { font-weight: bold } /* Keyword.Declaration */
.highlight .kp { font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #7f9df6; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #0cdfdf } /* Literal.Number */
.highlight .s { color: #d14 } /* Literal.String */
.highlight .na { color: #37d6d6 } /* Name.Attribute */
.highlight .nb { color: #0086B3 } /* Name.Builtin */
.highlight .nc { color: #7f9df6; font-weight: bold } /* Name.Class */
.highlight .no { color: #37d6d6 } /* Name.Constant */
.highlight .ni { color: #800080 } /* Name.Entity */
.highlight .ne { color: #e50000; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #e50000; font-weight: bold } /* Name.Function */
.highlight .nn { color: #f6f6f6 } /* Name.Namespace */
.highlight .nt { color: #f7ffb3 } /* Name.Tag */
.highlight .nv { color: #37d6d6 } /* Name.Variable */
.highlight .ow { font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #0cdfdf } /* Literal.Number.Float */
.highlight .mh { color: #0cdfdf } /* Literal.Number.Hex */
.highlight .mi { color: #0cdfdf } /* Literal.Number.Integer */
.highlight .mo { color: #0cdfdf } /* Literal.Number.Oct */
.highlight .sb { color: #d14 } /* Literal.String.Backtick */
.highlight .sc { color: #d14 } /* Literal.String.Char */
.highlight .sd { color: #d14 } /* Literal.String.Doc */
.highlight .s2 { color: #d14 } /* Literal.String.Double */
.highlight .se { color: #d14 } /* Literal.String.Escape */
.highlight .sh { color: #d14 } /* Literal.String.Heredoc */
.highlight .si { color: #d14 } /* Literal.String.Interpol */
.highlight .sx { color: #d14 } /* Literal.String.Other */
.highlight .sr { color: #04df3b } /* Literal.String.Regex */
.highlight .s1 { color: #d14 } /* Literal.String.Single */
.highlight .ss { color: #f209b8 } /* Literal.String.Symbol */
.highlight .bp { color: #d2d2d2 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #37d6d6 } /* Name.Variable.Class */
.highlight .vg { color: #37d6d6 } /* Name.Variable.Global */
.highlight .vi { color: #37d6d6 } /* Name.Variable.Instance */
.highlight .il { color: #0cdfdf } /* Literal.Number.Integer.Long */

.highlight .lineno { color: #ccc; display:inline-block; padding: 0 5px; border-right:1px solid #ccc; }
.highlight pre code { display: block; white-space: pre; overflow-x: auto; word-wrap: normal; }
